let GetBaseURL = process.env.REACT_APP_PURCHASE_URL;
let GetBaseURlTravel = process.env.REACT_APP_PURCHASE_URL_TRAVEL;
let appbase = process.env.REACT_APP_REDIRECT_URL;

export const ProductList = {
  SME: "SME",
  MOTOR: "MOTOR",
  MYFAMILY: "MYFAMILY",
  MBI: "MBI",
  TRAVEL: "TRAVEL",
  MEDICALMAL: "MEDICALMAL",
  VISTVISA: "VISTVISA",
  DOMESTICWORKER: "DOMESTICWORKER",
  PARENTS: "PARENTS",
};
export const externalLinks = {
  smeLink: "/medicalsme?lang=en",
  smelinkar: "/medicalsme?lang=ar",
  medmal: GetBaseURL + "medmal",
  Motor: appbase + "/motor",
  DomesticWorkerHealth: GetBaseURL + "domestic-worker",
  ParentHealth: appbase + "/parents-insurance",
  smeMotor: appbase + "/motor-sme",
  smeGeneral: appbase + "/property-and-casualty-360",
  myFamily: appbase + "/my-family-insurance",
  Renewal: GetBaseURlTravel,
  medmal2: GetBaseURL,
  common: GetBaseURL,
  travel: GetBaseURlTravel,
  baseurl: appbase + "/",
  claimAssistance: "/login",
  homeInsurance: GetBaseURL + "homeInsurance",
  visitVisa: appbase + "/visit-visa",
  smeHealth: "/medicalsme",
  homeInsurance_v3: appbase + "/home-insurance",
  travelInsurance_v3: appbase + "/travel-insurance",
  medmal_v3: appbase + "/medical-malpractice",
  premium: appbase + "/premium-residency",
  domesticWorker_v3: "/domestic-worker",
  medicalSME_v3: "/medicalsme"
};
console.log("externalLinks", process.env);

export const investorPDFLinks = {
  //2024
  pdf_en_2024_invest: "https://tawuniya.com/docs/485_0_2024-05-09_12-26-26_En_Q1_2024.pdf",
  pdf_ar_2024_invest: "https://tawuniya.com/docs/485_0_2024-05-09_12-26-26_Ar_Q1.pdf",
  pdf_en_2024_invest_Q2: "https://tawuniya.com/docs/2024_Q2_Financial_Statements.pdf",
  pdf_en_2024_invest_Q3: "https://tawuniya.com/docs/Tawuniya_Q3_FS-English.pdf",
  pdf_ar_2024_invest_Q3: "https://tawuniya.com/docs/Tawuniya_Q3_FS-Arabic.pdf",
  pdf_en_2024_invest_Q4: "https://tawuniya.com/docs/2024_Q4_Financial_Statements_EN.pdf",
  pdf_ar_2024_invest_Q4: "https://tawuniya.com/docs/2024_Q4_Financial_Statements_AR.pdf",
  // 2023
  pdfQ12023: "https://www.tawuniya.com/docs/485_0_2023-06-22_14-59-48_En.pdf",
  pdfQ12023_ar:
    "https://www.tawuniya.com/docs/485_0_2023-06-22_14-59-48_Ar.pdf",
  pdfQ22023: "https://www.tawuniya.com/docs/485_0_2023-08-14_16-03-36_En.pdf",
  pdfQ22023_ar:
    "https://www.tawuniya.com/docs/485_0_2023-08-14_16-03-36_Ar.pdf",
  pdfQ32023: "https://www.tawuniya.com/docs/485_0_2023-11-09_15-59-48_En.pdf",
  pdfQ42023: "https://www.tawuniya.com/docs/485_0_2024-03-14_15-27-23_En.pdf",
  pdfQ42023_ar: "https://www.tawuniya.com/docs/485_0_2024-03-14_15-27-23_Ar.pdf",
  annual_report_2023: "https://www.tawuniya.com/docs/BODEn.pdf",
  annual_report_2023_ar: "https://www.tawuniya.com/docs/BOD_Ar.pdf",
  pdf_en_2023_invest: "https://tawuniya.com/docs/TAWUNIYA_REPORT_2023_ENGLISH.pdf",
  pdf_ar_2023_invest: "https://tawuniya.com/docs/TAWUNIYA_REPORT_2023_ARABIC.pdf",
  //2022
  pdf1: "https://www.tawuniya.com/docs/485_0_2023-04-05_14-50-15_En.pdf",
  pdf1Ar: "https://www.tawuniya.com/docs/485_0_2023-04-05_14-50-15_Ar.pdf",
  pdf1_: "https://tawuniya.com/inv/",
  pdf1Ar_: "https://tawuniya.com/inv/ar/",
  pdf2: "https://www.tawuniya.com/docs/finance_reports/Q1_2022_en.pdf",
  arabicPDF2: "https://www.tawuniya.com/docs/finance_reports/Q1_2022-_ar.pdf",
  pdf3: "https://www.tawuniya.com/docs/Q2_2022_En.pdf",
  arabicPDF3: "https://www.tawuniya.com/docs/Q2_2022_Ar.pdf",
  pdf4: "https://www.tawuniya.com/docs/Q3_2022_En.pdf",
  arabicPDF4: "https://www.tawuniya.com/docs/Q3_2022_Ar.pdf",
  pdf5: "https://www.tawuniya.com/docs/485_0_2023-03-16_14-09-07_En.pdf",
  arabicPDF5: "https://www.tawuniya.com/docs/485_0_2023-03-16_14-09-07_Ar.pdf",
  pdf_ar_2022_invest: "https://www.tawuniya.com/docs/TAWUNIYA_REPORT_2022_ENGLISH_Digital_FEB2024.pdf",
  pdf_en_2022_invest: "https://www.tawuniya.com/docs/TAWUNIYA_REPORT_AN_2024_Arabic_8_February.pdf",
  //2021
  pdf6: "https://www.tawuniya.com/docs/finance_reports/annual-report-2021-en.pdf",
  arabicPDF6:
    "https://www.tawuniya.com/docs/finance_reports/annual-report-2021-ar.pdf",
  pdf7: "https://www.tawuniya.com/docs/finance_reports/q1-2021_en.pdf",
  arabicPDF7: "https://www.tawuniya.com/docs/finance_reports/q1-2021_ar.pdf",
  pdf8: "https://www.tawuniya.com/docs/finance_reports/q2_2021_en.pdf",
  arabicPDF8: "https://www.tawuniya.com/docs/finance_reports/q2_2021_ar.pdf",
  pdf9: "https://www.tawuniya.com/docs/finance_reports/q3-2021_en.pdf",
  arabicPDF9: "https://www.tawuniya.com/docs/finance_reports/q3-2021-ar.pdf",
  pdf10: "https://www.tawuniya.com/docs/finance_reports/Q4_2021_en.pdf",
  arabicPDF10: "https://www.tawuniya.com/docs/finance_reports/Q4_2021_ar.pdf",
  pdf11: "https://www.tawuniya.com/docs/full_report.pdf",
  arabicPDF11:
    "https://www.tawuniya.com/docs/TAWUNIYA_REPORT_Arabic_DIGITAL.pdf",
  //2019
  pdf12:
    "https://www.tawuniya.com/docs/finance_reports/2019-annual-report_en.pdf",
  arabicPDF12:
    "https://www.tawuniya.com/docs/finance_reports/2019_annual_report_ar.pdf",
  pdf13: "https://www.tawuniya.com/docs/finance_reports/q1_2019_en.pdf",
  arabicPDF13: "https://www.tawuniya.com/docs/finance_reports/q1-2019-ar.pdf",
  pdf14: "https://www.tawuniya.com/docs/finance_reports/q2_2019-en.pdf",
  arabicPDF14: "https://www.tawuniya.com/docs/finance_reports/q2_2019-ar.xlsx",
  pdf15: "https://www.tawuniya.com/docs/finance_reports/q3_2019_en.pdf",
  arabicPDF15: "https://www.tawuniya.com/docs/finance_reports/q3_2019_ar.pdf",
  pdf16: "https://www.tawuniya.com/docs/finance_reports/q4_2019_en.pdf",
  arabicPDF16: "https://www.tawuniya.com/docs/finance_reports/q4_2019_ar.pdf",
  pdf17: "https://www.tawuniya.com/docs/finance_reports/bord_report-2019.pdf",
  arabicPDF17:
    "https://www.tawuniya.com/docs/finance_reports/bord_report-2019.pdf",
  //2018
  pdf18:
    "https://www.tawuniya.com/docs/finance_reports/2018-annual-report_en.pdf",
  arabicPDF18:
    "https://www.tawuniya.com/docs/finance_reports/2018-annual-report-ar.pdf",
  pdf19: "https://www.tawuniya.com/docs/finance_reports/q1-2018-en.pdf",
  arabicPDF19: "https://www.tawuniya.com/docs/finance_reports/q1-2018-ar.pdf",
  pdf20: "https://www.tawuniya.com/docs/finance_reports/q2-2018-en.pdf",
  arabicPDF20: "https://www.tawuniya.com/docs/finance_reports/q2-2018-ar.pdf",
  pdf21: "https://www.tawuniya.com/docs/finance_reports/q3-2018-en.pdf",
  arabicPDF21: "https://www.tawuniya.com/docs/finance_reports/q3-2018-ar.pdf",
  pdf22: "https://www.tawuniya.com/docs/finance_reports/q4-2018-en.pdf",
  arabicPDF22: "https://www.tawuniya.com/docs/finance_reports/q4_2018_ar.pdf",
  pdf23:
    "https://www.tawuniya.com/docs/finance_reports/2018-bord-report-ar.pdf",
  arabicPDF23:
    "https://www.tawuniya.com/docs/finance_reports/2018-bord-report-ar.pdf",
  //2017
  pdf24:
    "https://www.tawuniya.com/docs/finance_reports/2017-annual-report_en.pdf",
  arabicPDF24:
    "https://www.tawuniya.com/docs/finance_reports/2017-annual-report_en.pdf",
  pdf25: "https://www.tawuniya.com/docs/finance_reports/q1-2017-en.pdf",
  arabicPDF25: "https://www.tawuniya.com/docs/finance_reports/q1_2017_ar.pdf",
  pdf26: "https://www.tawuniya.com/docs/finance_reports/q2-2017-en.pdf",
  arabicPDF26: "https://www.tawuniya.com/docs/finance_reports/q2_2017_ar.pdf",
  pdf27: "https://www.tawuniya.com/docs/finance_reports/q3-2017-en.pdf",
  arabicPDF27: "https://www.tawuniya.com/docs/finance_reports/q3_2017_ar.pdf",
  pdf28: "https://www.tawuniya.com/docs/finance_reports/q4-2017-en.pdf",
  arabicPDF28: "https://www.tawuniya.com/docs/finance_reports/q4-2017_ar.pdf",
  pdf29:
    "https://www.tawuniya.com/docs/finance_reports/2017-bord-report-ar.pdf",
  arabicPDF29:
    "https://www.tawuniya.com/docs/finance_reports/2017-bord-report-ar.pdf",
  pdf30: "https://www.tawuniya.com/docs/finance_reports/report-2017.pdf",
  arabicPDF30: "https://www.tawuniya.com/docs/finance_reports/report-2017.pdf",
  //2016
  pdf31: "https://www.tawuniya.com/docs/finance_reports/2016-annual-report.pdf",
  arabicPDF31:
    "https://www.tawuniya.com/docs/finance_reports/2016-annual-report.pdf",
  pdf32: "https://www.tawuniya.com/docs/finance_reports/q1-2016-en.pdf",
  arabicPDF32: "https://www.tawuniya.com/docs/finance_reports/q1-2016-ar.pdf",
  pdf33: "https://www.tawuniya.com/docs/finance_reports/q2-2016-en.pdf",
  arabicPDF33: "https://www.tawuniya.com/docs/finance_reports/q2-2016-ar.pdf",
  pdf34: "https://www.tawuniya.com/docs/finance_reports/q3-2016-en.pdf",
  arabicPDF34: "https://www.tawuniya.com/docs/finance_reports/q3-2016-en.pdf",
  pdf35: "https://www.tawuniya.com/docs/finance_reports/q4-2016-en.pdf",
  arabicPDF35: "https://www.tawuniya.com/docs/finance_reports/q4-2016-en.pdf",
  //2015
  pdf36:
    "https://www.tawuniya.com/docs/finance_reports/2015-annual-report-en.pdf",
  arabicPDF36:
    "https://www.tawuniya.com/docs/finance_reports/2015-annual-report-en.pdf",
  pdf37: "https://www.tawuniya.com/docs/finance_reports/q1-2015-ar.pdf",
  arabicPDF37: "https://www.tawuniya.com/docs/finance_reports/q1-2015-ar.pdf",
  pdf38: "https://www.tawuniya.com/docs/finance_reports/q2-2015-en.pdf",
  arabicPDF38: "https://www.tawuniya.com/docs/finance_reports/q2-2015-ar.pdf",
  pdf39: "https://www.tawuniya.com/docs/finance_reports/q3-2015-ar.pdf",
  arabicPDF39: "https://www.tawuniya.com/docs/finance_reports/q3-2015-ar.pdf",
  pdf40: "https://www.tawuniya.com/docs/finance_reports/q4-2015-en.pdf",
  arabicPDF40: "https://www.tawuniya.com/docs/finance_reports/q4-2015-ar.pdf",
  //2014
  pdf41: "https://www.tawuniya.com/docs/finance_reports/fy-2014-ar.pdf",
  arabicPDF41: "https://www.tawuniya.com/docs/finance_reports/fy-2014-ar.pdf",
  pdf42: "https://www.tawuniya.com/docs/finance_reports/q1-2014-ar.pdf",
  arabicPDF42: "https://www.tawuniya.com/docs/finance_reports/q1-2014-ar.pdf",
  pdf43: "https://www.tawuniya.com/docs/finance_reports/q2-2014-ar.pdf",
  arabicPDF43: "https://www.tawuniya.com/docs/finance_reports/q2-2014-ar.pdf",
  pdf44: "https://www.tawuniya.com/docs/finance_reports/q3-2014-ar.pdf",
  arabicPDF44: "https://www.tawuniya.com/docs/finance_reports/q3-2014-ar.pdf",
  pdf45: "https://www.tawuniya.com/docs/finance_reports/q4-2014-ar.pdf",
  arabicPDF45: "https://www.tawuniya.com/docs/finance_reports/q4-2014-ar.pdf",
  //2013
  pdf46: "https://www.tawuniya.com/docs/finance_reports/fy-2013-ar.pdf",
  arabicPDF46: "https://www.tawuniya.com/docs/finance_reports/fy-2013-ar.pdf",
  pdf47: "https://www.tawuniya.com/docs/finance_reports/q1-2013-ar.pdf",
  arabicPDF47: "https://www.tawuniya.com/docs/finance_reports/q1-2013-ar.pdf",
  pdf48: "https://www.tawuniya.com/docs/finance_reports/q2-2013-ar.pdf",
  arabicPDF48: "https://www.tawuniya.com/docs/finance_reports/q2-2013-ar.pdf",
  pdf49: "https://www.tawuniya.com/docs/finance_reports/q3-2013-ar.pdf",
  arabicPDF49: "https://www.tawuniya.com/docs/finance_reports/q3-2013-ar.pdf",
  pdf50: "https://www.tawuniya.com/docs/finance_reports/q4-2013-ar.pdf",
  arabicPDF50: "https://www.tawuniya.com/docs/finance_reports/q4-2013-ar.pdf",
  //2012
  pdf51:
    "https://www.tawuniya.com/docs/finance_reports/2012-annual-report-en.pdf",
  arabicPDF51:
    "https://www.tawuniya.com/docs/finance_reports/2012-annual-report-en.pdf",
  pdf52: "https://www.tawuniya.com/docs/finance_reports/q1-2012-ar.pdf",
  arabicPDF52: "https://www.tawuniya.com/docs/finance_reports/q1-2012-ar.pdf",
  pdf53: "https://www.tawuniya.com/docs/finance_reports/2q-2012-ar.pdf",
  arabicPDF53: "https://www.tawuniya.com/docs/finance_reports/2q-2012-ar.pdf",
  pdf54: "https://www.tawuniya.com/docs/finance_reports/3q-2012-ar.pdf",
  arabicPDF54: "https://www.tawuniya.com/docs/finance_reports/3q-2012-ar.pdf",
  pdf55: "https://www.tawuniya.com/docs/finance_reports/q4-2012-ar.pdf",
  arabicPDF55: "https://www.tawuniya.com/docs/finance_reports/q4-2012-ar.pdf",
  pdf56: "https://www.tawuniya.com/docs/finance_reports/fy-2012-ar.pdf",
  arabicPDF56: "https://www.tawuniya.com/docs/finance_reports/fy-2012-ar.pdf",
  //2011
  pdf57: "https://www.tawuniya.com/docs/finance_reports/2011-annual-repor1.pdf",
  arabicPDF57:
    "https://www.tawuniya.com/docs/finance_reports/2011-annual-repor1.pdf",
  pdf58: "https://www.tawuniya.com/docs/finance_reports/q1-2011-ar.pdf",
  arabicPDF58: "https://www.tawuniya.com/docs/finance_reports/q1-2011-ar.pdf",
  pdf59: "https://www.tawuniya.com/docs/finance_reports/q2-2011-ar.pdf",
  arabicPDF59: "https://www.tawuniya.com/docs/finance_reports/q2-2011-ar.pdf",
  pdf60: "https://www.tawuniya.com/docs/finance_reports/q3-2011-ar.pdf",
  arabicPDF60: "https://www.tawuniya.com/docs/finance_reports/q3-2011-ar.pdf",
  pdf61: "https://www.tawuniya.com/docs/finance_reports/q4-2011-ar.pdf",
  arabicPDF61: "https://www.tawuniya.com/docs/finance_reports/q4-2011-ar.pdf",
  pdf62: "https://www.tawuniya.com/docs/finance_reports/fy-2011-ar.pdf",
  arabicPDF62: "https://www.tawuniya.com/docs/finance_reports/fy-2011-ar.pdf",
  //2010
  pdf63: "https://www.tawuniya.com/docs/finance_reports/2010-annual-report.pdf",
  arabicPDF63:
    "https://www.tawuniya.com/docs/finance_reports/2010-annual-report.pdf",
  pdf64: "https://www.tawuniya.com/docs/finance_reports/q1-2010-ar.pdf",
  arabicPDF64: "https://www.tawuniya.com/docs/finance_reports/q1-2010-ar.pdf",
  pdf65: "https://www.tawuniya.com/docs/finance_reports/q2-2010-ar.pdf",
  arabicPDF65: "https://www.tawuniya.com/docs/finance_reports/q2-2010-ar.pdf",
  pdf66: "https://www.tawuniya.com/docs/finance_reports/q3-2010-ar.pdf",
  arabicPDF66: "https://www.tawuniya.com/docs/finance_reports/q3-2010-ar.pdf",
  pdf67: "https://www.tawuniya.com/docs/finance_reports/q4-2010-ar.pdf",
  arabicPDF67: "https://www.tawuniya.com/docs/finance_reports/q4-2010-ar.pdf",
  pdf68: "https://www.tawuniya.com/docs/finance_reports/fy-2010-ar.pdf",
  arabicPDF68: "https://www.tawuniya.com/docs/finance_reports/fy-2010-ar.pdf",
  //2009
  pdf69:
    "https://www.tawuniya.com/docs/finance_reports/2009-annual-report-en.pdf",
  arabicPDF69:
    "https://www.tawuniya.com/docs/finance_reports/2009-annual-report-ar.pdf",
  pdf70: "https://www.tawuniya.com/docs/finance_reports/q1-2009-ar.pdf",
  arabicPDF70: "https://www.tawuniya.com/docs/finance_reports/q1-2009-ar.pdf",
  pdf71: "https://www.tawuniya.com/docs/finance_reports/q2-2009-ar.pdf",
  arabicPDF71: "https://www.tawuniya.com/docs/finance_reports/q2-2009-ar.pdf",
  pdf72: "https://www.tawuniya.com/docs/finance_reports/q3-2009-ar.pdf",
  arabicPDF72: "https://www.tawuniya.com/docs/finance_reports/q3-2009-ar.pdf",
  pdf73: "https://www.tawuniya.com/docs/finance_reports/q4-2009-ar.pdf",
  arabicPDF73: "https://www.tawuniya.com/docs/finance_reports/q4-2009-ar.pdf"
};

export const LandingSiteFilesLink = {
  CorporateGovernanceFile:
    "https://www.tawuniya.com/docs/Corporate_Governanace_2023.pdf",
  CustomerProtectionFile: "https://www.tawuniya.com/docs/Insurance-Consumer-Protection-Principles-en.pdf",
  CustomerProtectionFilear: "https://www.tawuniya.com/docs/Insurance-Consumer-Protection-Principles-ar.pdf",
  HajjFileEnglish: "https://tawuniya.com/docs/HajjInsurance.pdf",
  HajjFileArabic:
    "https://www.tawuniya.com/docs/Hajj_insurance_policy_Arabic.pdf",
  UmrahFileArabic: "https://tawuniya.com/docs/UmrahInsuranceArabic.pdf",
  UmrahFileEnglish: "https://tawuniya.com/docs/UmrahInsuranceEnglish.pdf",
  MechanicalBreakdownFile:
    "https://tawuniya.com/docs/MechanicalBreakdownInsuranceBrochure.pdf",
  ShariaEngFile: "https://tawuniya.com/docs/sharia-eng.pdf",
  AssistAmericaFileEng:
    "https://tawuniya.com/docs/tawuniya_assist-america-brochure_-english.pdf",
  AssistAmericaFileArabic:
    "https://tawuniya.com/docs/tawuniya_assist-america-brochure_-arabic.pdf",
  TawEarningsCofFile:
    "https://tawuniya.com/docs/TawuniyasEarningsConferenceCall.pdf",
  ProtectionRule:
    "http://www.sama.gov.sa/ar-sa/Laws/ConsumerProtectionRules/%D9%85%D8%A8%D8%A7%D8%AF%D8%A6%20%D8%AD%D9%85%D8%A7%D9%8A%D8%A9%20%D8%B9%D9%85%D9%84%D8%A7%D8%A1%20%D8%B4%D8%B1%D9%83%D8%A7%D8%AA%20%D8%A7%D9%84%D8%AA%D8%A3%D9%85%D9%8A%D9%86.pdf#search=%D9%85%D8%A8%D8%A7%D8%AF%D8%A6%20%D8%AD%D9%85%D8%A7%D9%8A%D8%A9%20%D8%B9%D9%85%D9%84%D8%A7%D8%A1%20%D8%B4%D8%B1%D9%83%D8%A7%D8%AA%20%D8%A7%D9%84%D8%AA%D8%A3%D9%85%D9%8A%D9%86",
  RegulationPDF:
    "http://www.sama.gov.sa/en-US/Laws/InsuranceRulesAndRegulations/IIR_4600_%E2%80%8EOnline_IEN.pdf#search=Online%20Insurance%20Activities%20Regulation",
  guidLinePdf: "https://tawuniya.com/docs/Update_S_G_Tawuniya_Guidelines_21032023.pdf",
};

export const DesktopDL = {
  DDC: "https://med.tawuniya.com.sa/ords/apexrep/r/tawuniya1980/ecard?VC=323038121222HV91QTC&VCL=323038121222LF6IW",
  VCB: "https://med.tawuniya.com.sa/ords/apexrep/r/tawuniya1980/digtaletop?VC=323038121222HV91QTT&VCL=323038121222LF6IW",
  VMP: "https://www.tawuniya.com/?lang=ar",
  VMG: "https://med.tawuniya.com.sa/ords/apexrep/r/tawuniya1980/providersenhelpline2?VC=323038121222HV91QTN&VCL=323038121222LF6IW",
  GME: "https://med.tawuniya.com.sa/ords/apexrep/r/tawuniya1980/providersenhelpline2?VC=323038121222HV91QTN&VCL=323038121222LF6IW"
};
export const MobileDL = {
  DDC: "https://tawuniyainsurance.page.link/2L6u",
  VCB: "https://tawuniyainsurance.page.link/gZ9b",
  VMP: "https://tawuniyainsurance.page.link/Nweg",
  VMG: "https://tawuniyainsurance.page.link/TJBU",
  GME: "https://tawuniyainsurance.page.link/ez2G"
};
export const LandingSiteDeepLink = {
  DDC: window.innerWidth <= 600 ? MobileDL.DDC : DesktopDL.DDC,
  VCB: window.innerWidth <= 600 ? MobileDL.VCB : DesktopDL.VCB,
  VMP: window.innerWidth <= 600 ? MobileDL.VMP : DesktopDL.VMP,
  VMG: window.innerWidth <= 600 ? MobileDL.VMG : DesktopDL.VMG,
  GME: window.innerWidth <= 600 ? MobileDL.GME : DesktopDL.GME
};
